import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "/assets/images/thoughts/on-anxiety/joseph-smith.png",
        "alt": "Joseph Smith Memorial Building"
      }}></img></p>
    <p>{`This weekend I attended a conference about anxiety disorders. Knowing how
difficult, painful and extreme peoples circumstances and experiences are...
makes me feel woefully inadequate to share any insight. That said, I'd like to
take the risk. :-)`}</p>
    <p>{`Sometimes anxiety floods us about the future, our loved ones, ourselves, making
mistakes, the world, and on and on. The anxiety and uncertainty that we feel can
pick us to pieces and leave us paralyzed in a depression. It can cause one to
avoid and hide from all things uncomfortable and as a result, make a nice
comfortable prison of stagnation. For religious people, it often comes with
anxiety about our salvation, their faith or about God.`}</p>
    <p>{`Here is the truth... There is a risk. Your anxieties could be "right". The end
could come tomorrow. You could lose your job. You could get terminally ill. I
could be broken and hell bound. But...`}</p>
    <p>{`They could also be wrong. If we want to get into the probabilities of it most of
our fears are terribly unlikely; it's that room for the possibility that drives
our minds to make us feel safe. Accept the possibility and the monster that is
your fear will be much smaller than you can now imagine. Hard to tell if you've
always been running from it just how big that fear is.`}</p>
    <p>{`Is there a risk? Of course, there is. Take the risk of believing you are more
resilient than anything that could happen to you. Take the risk of believing
there is a God that is so good, so kind, so loving, so merciful, so eager to
share His joy with you that maybe you don't need to be so paralyzed by your fear
of being perfect. That whatever your problems you and God are bigger. Could I be
wrong? Absolutely. It's a risk that seems utterly more dangerous not to take.
Dare to believe in your resilience and dare to believe in Him.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      